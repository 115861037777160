<template>
  <div>
    <div class="row">
      <div class="col-md-7 col-sm-12 col-lg-7">
        <p><b>Select Server Name To Control</b></p>
        <select class="form-control custom-select" @change="onchange()" v-model="serverName" name="selectType" v-validate="{required:true}">
          <option value="">Select Type</option>
          <option value="Stage">Stage</option>
          <option value="Prod">Prod</option>
        </select>
      </div>

    </div>
    <div v-if="serverName!=''" style="width:50%;height:50%;border:solid 1px;margin-top:10px;">
        <div>
          <h6 style="margin-bottom:10px;font-size: 15px;">{{serverName}} SetUp</h6>
          <hr style="border:solid 1px;"/>

        </div>
        <div>
          <button v-if="isShowONButton" class="button button1" @click="startServer()">ON</button>
          <button v-if="isShowOffButton" class="button button2" @click="stopServer()">OFF</button>
        </div>


    </div>
  </div>



</template>

<script>
var AWS = require('aws-sdk');
var {Signer} = require('@aws-amplify/core');
var axios =  require("axios");
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster();


//import { useRoute } from 'vue-router'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return {
      identityId:'',
      credentials:null,
      serverName:"",
      isShowONButton:false,
      isShowOffButton:false,

    }
  },
  mounted() {
    // const route = useRoute()
    // var comp = this;
    //console.log(comp.$route.query);
    //console.log(route.query)
  //  this.getIdentityToken();
  let uri = window.location.href.split('#');
  if(uri.length == 2) {
    let vars = uri[1].split('&');
    let idTokenObj = vars[0].split('=');
    let idToken = idTokenObj[1];
    console.log(idToken);
    this.getIdentityToken(idToken);



    // do
  }

  },
  methods:{
    async onchange(){
      console.log("selected server ="+JSON.stringify(this.serverName));
      this.getServerStatus()
    },
    async getServerStatus(){
      if (this.serverName == 'Stage') {
        var url = 'https://emrald-qa.emrx.in'+'/api/v1/releaseinfo';
        axios.get(url).then(response => {
          console.log("response  ="+JSON.stringify(response));
          if (response) {
            this.isShowONButton = false;
            this.isShowOffButton = true;
          }
        }).catch(err =>{
          this.isShowONButton = true;
          this.isShowOffButton = false;
          console.log("error while getting releaseinfo ="+JSON.stringify(err));
        });
      }
      else if(this.serverName == 'Prod'){
        var produrl = 'https://emrald-prod.emrx.in'+'/api/v1/releaseinfo';
        axios.get(produrl).then(response => {
          console.log("response  ="+JSON.stringify(response));
          if (response) {
            this.isShowONButton = false;
            this.isShowOffButton = true;
          }
        }).catch(err =>{
          this.isShowONButton = true;
          this.isShowOffButton = false;
          console.log("error while getting releaseinfo ="+JSON.stringify(err));
        });
      }
    },
    getIdentityToken(idToken){
      let comp = this;
      AWS.config.region = 'ap-south-1';
      var cognitoidentity = new AWS.CognitoIdentity();
      var params = {
        IdentityPoolId: 'ap-south-1:a6305d57-84ef-4507-9d8c-d3a6c308f941', /* required */
        AccountId: '260011671057',
        Logins: {
          'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_1aCvlcUH4':idToken
        }
      };
      cognitoidentity.getId(params, function(err, data) {
          if (err) {
              console.log(err, err.stack);
          }
          else    {
            this.identityId = data.IdentityId;
              console.log(data);
              console.log("this.identityId ="+this.identityId);
              let credentialParams = {
                IdentityId: data.IdentityId, /* required */
                Logins: {
                  'cognito-idp.ap-south-1.amazonaws.com/ap-south-1_1aCvlcUH4': idToken
                }
              };
              console.log("credentialParams = "+JSON.stringify(credentialParams));
              cognitoidentity.getCredentialsForIdentity(credentialParams, function(err, identitydata) {
                if (err) {
                    console.log(err, err.stack);
                }
                else    {
                    console.log(identitydata);
                    comp.credentials = identitydata.Credentials
                    console.log("credentials ="+JSON.stringify(comp.credentials));


                    }
                })
          }
      });

    },
    async serverControlChange(url,toastMessage){
      let request = {
        method: 'GET',
        url: url,
        data: ''
        }
        let access_info =
        {
            region: "ap-south-1",
            access_key: this.credentials.AccessKeyId,
            secret_key: this.credentials.SecretKey,
            session_token: this.credentials.SessionToken
        };
        let service_info = {service: 'execute-api',
                          region: 'ap-south-1'}
        let signedRequest =  Signer.sign(request,access_info,service_info)
        var instance = axios.create();
        let response =  instance(signedRequest)
        .then(function (response)
        {
          console.log(response.data);
          let message ='Successfully  ' + toastMessage + ' Service';
          toaster.success(message);                          //   return response

        })
        .catch(function (error) {
            console.log("error occured while updating server =="+JSON.stringify(error));

            toaster.error('Error Occured While' + toastMessage + 'Service');
        });
        console.log("axios response ="+JSON.stringify(response));
    },
    async startServer(){
      let url = "https://control.emrx.in/start?clusterName="
      if (this.serverName == 'Stage') {
        url = url + 'emrald-staging'
      }
      if (this.serverName == 'Prod') {
        url = url+'emrald-prod'
      }
      let toastMessage = 'Start';
      this.serverControlChange(url,toastMessage);

    },
    async stopServer(){
      let url = "https://control.emrx.in/stop?clusterName="
      if (this.serverName == 'Stage') {
        url = url + 'emrald-staging'
      }
      if (this.serverName == 'Prod') {
        url = url+'emrald-prod'
      }
      let toastMessage = 'Stop';
      this.serverControlChange(url,toastMessage);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.button {
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button1 {
  background-color: white;
  color: black;
  border: 2px solid #4CAF50;
}

.button1:hover {
  background-color: #4CAF50;
  color: white;
}

.button2 {
  background-color: white;
  color: black;
  border: 2px solid #FF0000;
}

.button2:hover {
  background-color: #FF0000;
  color: white;
}
</style>
