<template>
<div style="margin-left:500px;margin-right:30px;text-align:center;">
    <div style="width:50%;height:50%;border:solid 1px;margin-top:10px;">
      <div style="text-align:center;">
        <p><b>Login To Emrlad Control Panel</b></p>
        <button class="button button1" style="margin-bottom:10px;" @click="onLogin()" >Login</button>
      </div>
    </div>

  </div>
</template>
<script>
export default {
    name: 'LoginPage',
    data(){
      return {

      }
    },
    methods:{
        onLogin(){
            window.open("https://controlui.auth.ap-south-1.amazoncognito.com/login?client_id=6va0snon5vbjb9dva06fvsfs24&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://controlui.emrx.in/controlpanelLogin");
            //this.$router.push('controlpanelLogin')
        }
    }
}
</script>
<style scoped>
.button {
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button1 {
  background-color: white;
  color: black;
  border: 2px solid #4CAF50;
}

.button1:hover {
  background-color: #4CAF50;
  color: white;
}

</style>
