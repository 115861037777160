import { createWebHistory, createRouter } from "vue-router";
import HelloWorld from "@/components/HelloWorld.vue";
import LoginPage from "@/components/Login.vue";

const routes = [
    {
        path: "/",
        name: "Login",
        component: LoginPage,
        },
  {
    path: "/controlpanelLogin",
    name: "HelloWorld",
    component: HelloWorld,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
